@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html,body {
  height: 100%;
  width: 100%;
  background-color:#fff;
}

.input-custom {
  width: 100%;
  height: 36px;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 14px;
  color: #666;
  box-sizing: border-box;
  outline: none;
  background: #f4f5f9;
  &::placeholder {
    color: #b9b9b9;
  }
}

@button-primary-background-color: #FFC728;@button-primary-border-color: #FFC728;@button-default-border-color: #F4F5F9;@button-default-background-color: #F4F5F9;@text-color: #222222;